import React from 'react';

const Logo = props => {
  return (
    <div className="logo">
      {(props.logoWhite)?
      <img className="logo-img" alt="Logo Gestión B-white" src="/img/logo-gestionb-w.png" />
      :
      <img className="logo-img" alt="Logo Gestión B" src="/img/logo-gestionb.png" />
      }
      <img className="logo-img logo-sep d-none d-md-inline" alt="Empresa B" src="/img/certificada.png" />
    </div>
  );
}

export default Logo;