import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { navigate, Link } from 'gatsby'
import { reset, unsetError } from '../store/actions/useAuth'
import { Spinner  } from 'react-bootstrap';
// import './login.css'

const Reset = ({ redirect }) => {
  const dispatch = useDispatch()

  const userCargando = useSelector( state => state.auth.loading );
  const loggedIn = useSelector( state => state.auth.loggedIn );

  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setRePassword] = useState('')
  const [error, setError] = useState(null)
  const [enviado, setEnviado] = useState(false)

  useEffect(() => {
    setError(null)
    let params = new URLSearchParams(document.location.search.substring(1));
    let code1 = params.get("code")
    setCode(params.get("code"))
    console.log(code1)
    dispatch( unsetError() )
    setError(null)
  }, []);

  const handleSubmit = async (event) => {
    setError(null)
    event.preventDefault()
    if ( password === "" ){
      setError("Debe ingresar el nuevo password.")
      return false;
    }else if ( passwordConfirmation === "" ){
      setError("Debe repetir el nuevo password.")
      return false;
    }else if ( passwordConfirmation !== password ){
      setError("Las password ingreadas no coinciden.")
      return false;
    }
    try{
      const retReset = await dispatch( reset({ code, password , passwordConfirmation }) )
      
      if(retReset.status){
        navigate(redirect)
      }
      else{
        setError(retReset.msg);
      }
    }catch(e){
      console.error(e)
    }
  }

  useEffect(() => {
    if(loggedIn){
      navigate("/app/dashboard")
    }
  }, [])

  return (
    (loggedIn)?
    ""
    :
    <div className="wrapper fadeInDown">
        <div id="formContent">
            {/* <!-- Tabs Titles --> */}

            {/* <!-- Icon --> */}
            <div className="fadeIn first mb-2">
            {/* <i className="fas fa-sign-in-alt"></i> */}
            <h5>Restablecer password</h5>
            </div>

            {/* <!-- Login Form --> */}
            <form onSubmit={handleSubmit}>
              <label className="text-uppercase mb-3">Ingresar Passwords</label>
              <input
                  className="form-control fadeIn second mb-3"
                  onChange={ e => {
                    setPassword(e.target.value)
                  }}
                  value={ password }
                  disabled={userCargando}
                  id="password" type="password" placeholder="Nuevo Password"/>
              <input
                  className="form-control fadeIn third mb-3"
                  onChange={ e => {
                    setRePassword(e.target.value)
                  }}
                  value={ passwordConfirmation }
                  disabled={userCargando}
                  id="re-password" type="password" placeholder="Repetir Nuevo Pasword"/>
              <button type="submit" className="btn mb-2" disabled={userCargando}>Restablecer Password</button>
            </form>

            {/* <!-- Remind Passowrd --> */}
            <div id="formFooter mb-2">
                { (error && error.length > 1) && (
                    <p className="alert alert-danger mb-2">
                    { error }
                    </p>
                )}
                { (!error && !userCargando && enviado) && (
                    <p className="alert alert-success mb-2">
                    Se seteado el password correctamente.
                    </p>
                )}
            </div>
            {(userCargando)?
            <div className="p-2"><Spinner animation="border" variant="info" /></div>              
            :
            ""
            }
            <Link className="font-weight-bold" to={redirect}>Volver al login</Link>

        </div>
    </div>
  )
}

export default Reset