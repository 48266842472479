import React from "react"
import LoginLayout from '../layouts/LoginLayout'
import Reset from '../components/Reset'

const ResetPage = () => {
  const redirect = "/login"
  
  return (
    <LoginLayout title="Gestión B - Resetear clave">
        <Reset redirect={redirect} />
    </LoginLayout>
  )
}

export default ResetPage