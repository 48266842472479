import React from 'react';

import { Helmet } from 'react-helmet'

import Logo from '../components/Logo'
import '../sass/login.sass'

const Login = ({ children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="Descripción" />
        <meta name="author" content="CaWot" />
      </Helmet>
      <section className="login w-100 flex-wrap justify-content-center">
        
        <div className="d-flex justify-content-between w-100 header pb-3 pt-0">
          <div className="d-none d-md-block"></div>
          <div className="d-flex justify-content-center w-100 pt-2">
            <img className="logo-img p-3" alt="Logo Gestión B" src="/img/logo-gestionb.png" />
          </div>
          <div className="d-none d-md-block pr-3">
            <img className="certificado d-none d-md-inline pt-1 pl-3 pr-3" alt="Empresa B" src="/img/certificada-w.png" />
          </div>
        </div>
        
        {/* contenido */}
        <div className="body d-flex justify-content-center w-100">
          <section className="col-sm-12 col-md-3 pt-3 pb-5">
            {children}
          </section>
        </div>
        {/* contenido */}

        <div className="footer d-flex flex-wrap w-100 p-2">
          <div className="col-sm-12 col-md-4 col-lg-3 p-2 text-left d-none d-md-block">
            <img className="footer-logo pr-5" alt="Logo Gestión B, Blanco" src="/img/logo-gestionb-w.png" />
          </div>
          <div className="col-sm-12 col-md-4 p-1 text-left d-none d-md-block">
            {/* <h4>800 000 000</h4>
            <h6>Horario de atención <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span></h6> */}
          </div>
          <div className="col-sm-12 col-md-4 p-1 text-left d-none d-md-block">
            <h4 className="mt-4">contacto@gestionb.cl</h4>
            {/* <h4>contacto@gestionb.cl</h4> */}
            {/* <h6>Horario de atención <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span></h6> */}
          </div>
          <div className="col-sm-12 col-md-2 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
            <img className="footer-logo" alt="Logo Gestión B, Blanco" src="/img/logo-gestionb-w.png" />
          </div>
          <div className="col-sm-12 col-md-4 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
            {/* <h4>800 000 000</h4>
            <h6>Horario de atención</h6>
            <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span> */}
          </div>
          <div className="col-sm-12 col-md-4 p-2 text-center d-xs-block d-sm-block d-md-none d-lg-none">
            <h4>contacto@gestionb.cl</h4>
            {/* <h6>Horario de atención</h6> */}
            {/* <span>lun-vier 10:00 - 18:00 / sab 10:00 - 14:30</span> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;